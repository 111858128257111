import React, { useEffect, useMemo, useState } from 'react'
import ErrorContent from '../components/auth/ErrorContent'
import './auth.css'
import Notification from '../components/Notification'
import notificationTypes from '../components/Notification/notificationTypes'
import { Button } from '../components/Button'

const ManagerAuth = ({
   handleToggle,
   isManager,
   isAmoLogin,
   authError,
   showResetPasswordSuccessNotification,
   onSuccessNotificationClose,
   onLogin,
   onForgotPassword
}) => {
   const [managerLogin, setManagerLogin] = useState('')
   const [managerPassword, setManagerPassword] = useState('')
   const [showPassword, setShowPassword] = useState(false)
   const [disableLoginBtn, setDisableLoginBtn] = useState(false)
   const [formStatus, setFormStatus] = useState('')
   
   const handleManagerLogin = async () => {
      setDisableLoginBtn(true)
      var bodyFormData = new FormData()
      bodyFormData.append('email', managerLogin)
      bodyFormData.append('password', managerPassword)
      const error = await onLogin(bodyFormData)
      setFormStatus(error ? error : '')
      setDisableLoginBtn(false)
   }

   const handleForgotPasswordClick = () => onForgotPassword()

   const errorDefault = 'default_error'

   const appHost = new URL(process.env.REACT_APP_AUTH_LINK).origin

   const errorData = useMemo(
      () => ({
         user_not_found: {
            head: (
               <>
                  Вы не подключили сервис <br /> интеграции i2crm
               </>
            ),
            message: (
               <>
                  Для подключения и настройки сервиса <br /> перейдите по{' '}
                  <a className="amo-login-error-link" href={appHost}>
                     ссылке
                  </a>
               </>
            ),
         },
         manager_not_found_error: {
            head: 'Не найден аккаунт менеджера',
            message: 'Обратитесь к администратору аккаунта.',
         },
         default_error: {
            head: 'Внутренняя ошибка.',
            message: 'Произошла непредвиденная ошибка, попробуйте позже.',
         },
      }),
      []
   )

   const renderError = (key) => {
      let errorKey = !!errorData[key] ? key : errorDefault
      return (
         <ErrorContent
            head={errorData[errorKey].head}
            message={errorData[errorKey].message}
         />
      )
   }

   return (
      <div className="auth">
         <div className="login-card manager-login">
            <div className="login-card-wrapper">
               <div className="login-card-header-wrapper">
                  <div className="login-card-header">
                     <button
                        type="button"
                        className="login-card-header__return-btn"
                        onClick={handleToggle}
                     >
                        <img
                           src="/assets/controls/return-back-icon.svg"
                           alt=""
                        />
                     </button>
                     <h2 className="login-card-header-title">Авторизация</h2>
                  </div>
                  <p className="login-card-header__description">
                     Вход для менеджера
                  </p>
               </div>
               {authError ? (
                  renderError(authError)
               ) : (
                  <div className="login-form-wrapper">
                     <div className="login-card-body-wrapper manager-login">
                        {formStatus && (
                           <p className="login-form__status">{formStatus}</p>
                        )}
                        <div className="login-form-control">
                           <label htmlFor="email">Почта</label>
                           <input
                              id="email"
                              autoComplete="off"
                              type="text"
                              className="login-card-credentials-field"
                              placeholder="Почта"
                              value={managerLogin}
                              onChange={(e) =>
                                 setManagerLogin(e.target.value.trim())
                              }
                           />
                        </div>
                        <div className="login-form-control">
                           <label htmlFor="password">Пароль</label>
                           <input
                              id="password"
                              autoComplete={
                                 showPassword ? 'text' : 'new-password'
                              }
                              type={showPassword ? 'text' : 'password'}
                              className="login-card-credentials-field password"
                              placeholder="Пароль"
                              value={managerPassword}
                              onChange={(e) =>
                                 setManagerPassword(e.target.value)
                              }
                           />
                           <img
                              className={`manager-login__show-btn ${
                                 showPassword
                                    ? 'manager-login__show-btn_active'
                                    : ''
                              }`}
                              src={`/assets/controls/password-${
                                 showPassword ? 'visible' : 'hidden'
                              }-icon.svg`}
                              alt=""
                              onClick={() => setShowPassword((prev) => !prev)}
                           />
                        </div>
                     </div>
                     <div className="login-card-button-wrapper manager-login">
                        <Button
                           text="Войти"
                           customStyle="manager-login__submit-btn"
                           onClick={handleManagerLogin}
                           disabled={disableLoginBtn}
                        />
                     </div>
                     <div className="login-card-forgot-password">
                        <button
                           className="login-card-forgot-password__btn"
                           onClick={handleForgotPasswordClick}
                        >
                           Забыли пароль?
                        </button>
                     </div>
                  </div>
               )}
            </div>
            {showResetPasswordSuccessNotification && (
               <Notification
                  type={notificationTypes.SUCCESS}
                  text="Пароль успешно изменен"
                  onClose={onSuccessNotificationClose}
               />
            )}
         </div>
      </div>
   )
}

export default ManagerAuth
