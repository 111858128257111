import React from 'react'

import notificationTypes from './notificationTypes'

import './Notification.css'

const Notification = ({
   type = notificationTypes.SUCCESS,
   text = '',
   onClose,
}) => {
   const handleClose = () => onClose()

   return (
      <div className={`notification notification_${type}`}>
         <div className="notification__body">
            <img
               src={`/assets/notifications/notification-icon-${type}.svg`}
               alt={type}
            />
            {text}
         </div>
         <button className="notification__close-btn" onClick={handleClose}>
            <img src="/assets/notifications/close-icon.svg" alt="Close" />
         </button>
      </div>
   )
}

export default Notification
