import React, { useEffect, useState } from 'react'
import { CSSTransition } from 'react-transition-group'
import { useFormik } from 'formik'
import * as yup from 'yup'
import './addContact.css'
import WarningBlock from './WarningBlock'
import { Button } from '../Button'

const AddTemplate = ({
   isVisible,
   title,
   text,
   templateEditModalData,
   accept,
   cancel,
   templateSubsections
}) => {
   const [showDropdown, setShowDropdown] = useState(false)
   const [disableSubmitBtn, setDisableSubmitBtn] = useState(false)

   const templateSubsection = templateSubsections.find(subsection => subsection.subsection_id === templateEditModalData?.subsection_id)

   const formik = useFormik({
      initialValues: {
         templateName: templateEditModalData?.name || '',
         templateText: templateEditModalData?.text || '',
         selectedSubsection: null,
         templateFiles: []
      },
      validationSchema: yup.object().shape({
         templateName: yup.string()
            .min(3, 'Название шаблона должно быть длиннее 2 символов')
            .max(46, 'Название шаблона должно быть не длиннее 46 символов')
            .required('Введите название шаблона'),
         templateText: yup.string().required('Введите текст шаблона')
      }),
      onSubmit: async ({ 
         templateName, 
         templateText, 
         selectedSubsection, 
         templateFiles 
      }, { setStatus }) => {
         try {
            setDisableSubmitBtn(true)
            if (
               templateName === templateEditModalData?.name && 
               templateText === templateEditModalData?.text &&
               selectedSubsection?.subsection_id === templateEditModalData?.subsection_id &&
               !templateFiles.length
            ) return cancel()

            const template = {
               name: templateName,
               text: templateText,
               subsectionId: selectedSubsection?.subsection_id || null
            }
            templateEditModalData && (template.id = templateEditModalData.id)
            templateFiles.length > 0 && (template.file = templateFiles[0])

            await accept(template)
            setStatus(null)
            formik.resetForm()
            cancel()
         } catch (err) {
            setStatus(err)
         } finally {
            setDisableSubmitBtn(false)
         }
      }
   })

   const displayWarningBlock = Object.keys(formik.errors).length > 0 && Object.keys(formik.touched).length > 0

   const toggleDropdown = () => setShowDropdown(show => !show)

   const handleSelectSubsection = (item) => {
      formik.setFieldValue('selectedSubsection', item)
      toggleDropdown()
   }

   const handleTemplateFilesChange = (e) => {
      formik.setFieldValue('templateFiles', [...e.target.files])
   }

   useEffect(() => {
      templateEditModalData && formik.setValues({
         ...formik.values,
         templateName: templateEditModalData.name,
         templateText: templateEditModalData.text
      })
      templateSubsection && formik.setFieldValue('selectedSubsection', templateSubsection)
   }, [templateEditModalData, templateSubsection])

   return (
      <CSSTransition
         in={isVisible}
         classNames="warning"
         timeout={500}
         mountOnEnter
         unmountOnExit
      >
         <div className="warning">
            <form className="add-contact-wrapper add-template-form" onSubmit={formik.handleSubmit}>
               <div className="add-contact-header">
                  <div className="add-contact-header-text">{title}</div>
                  <div className="add-contact-close">
                     <button
                        className="add-contact-close-button"
                        type="button"
                        onClick={() => {
                           formik.resetForm()
                           cancel()
                        }}
                     >
                        <img src="/assets/controls/close.svg" alt="" />
                     </button>
                  </div>
               </div>
               {formik.status && <p className='create-template-subsection-status'>{formik.status}</p>}
               <div className="add-contact-form-control">
                  <label
                     htmlFor=""
                     className={formik.errors.templateName && formik.touched.templateName
                           ? 'add-contact-form-control-label-red'
                           : 'add-contact-form-control-label'
                     }
                  >
                     {formik.errors.templateName || 'Название шаблона*'}
                  </label>
                  <input
                     type="text"
                     className={formik.errors.templateName && formik.touched.templateName
                           ? 'add-contact-form-control-input-red'
                           : 'add-contact-form-control-input'
                     }
                     placeholder="Введите название шаблона"
                     maxLength="46"
                     id='templateName'
                     name='templateName'
                     value={formik.values.templateName}
                     onChange={formik.handleChange}
                     onBlur={formik.handleBlur}
                  />
               </div>
               <div className="add-contact-form-control">
                  <label
                     htmlFor=""
                     className={formik.errors.templateText && formik.touched.templateText
                           ? 'add-contact-form-control-label-red'
                           : 'add-contact-form-control-label'
                     }
                  >
                     {formik.errors.templateText || 'Текст шаблона*'}
                  </label>
                  <textarea
                     className={formik.errors.templateText && formik.touched.templateText
                           ? 'add-contact-textarea-red'
                           : 'add-contact-textarea'
                     }
                     maxLength="1000"
                     id="templateText"
                     name="templateText"
                     value={formik.values.templateText}
                     onChange={formik.handleChange}
                     onBlur={formik.handleBlur}
                     placeholder="Текст шаблона"
                  ></textarea>
               </div>
               <div className="add-contact-form-control">
                  <label
                     htmlFor=""
                     className={'add-contact-form-control-label'}
                  >
                     Группа шаблона
                  </label>
                  <div className="dropdown add-contact-form-dropdown">
                     <div className="dropdown-wrapper">
                        <div
                           className="dropdown-current-item"
                           onClick={toggleDropdown}
                        >
                           <span className="dropdown-current-item_name">
                              {!formik.values.selectedSubsection
                                 ? 'Не выбрано'
                                 : formik.values.selectedSubsection.name
                              }
                           </span>
                           <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                           >
                              {showDropdown ? (
                                 <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M11.2197 8.21967C11.5126 7.92678 11.9874 7.92678 12.2803 8.21967L18.2803 14.2197C18.5732 14.5126 18.5732 14.9874 18.2803 15.2803C17.9874 15.5732 17.5126 15.5732 17.2197 15.2803L11.75 9.81066L6.28033 15.2803C5.98744 15.5732 5.51256 15.5732 5.21967 15.2803C4.92678 14.9874 4.92678 14.5126 5.21967 14.2197L11.2197 8.21967Z"
                                    fill="#252525"
                                 />
                              ) : (
                                 <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M5.21967 8.21967C5.51256 7.92678 5.98744 7.92678 6.28033 8.21967L11.75 13.6893L17.2197 8.21967C17.5126 7.92678 17.9874 7.92678 18.2803 8.21967C18.5732 8.51256 18.5732 8.98744 18.2803 9.28033L12.2803 15.2803C11.9874 15.5732 11.5126 15.5732 11.2197 15.2803L5.21967 9.28033C4.92678 8.98744 4.92678 8.51256 5.21967 8.21967Z"
                                    fill="#252525"
                                 />
                              )}
                           </svg>
                        </div>
                        {showDropdown && (
                           <div className="dropdown-list">
                              <div
                                 className="dropdown-list_wrapper"
                                 style={{ padding: '20px 24px' }}
                              >
                                 {templateSubsections.length > 0 
                                    ? (<ul className="dropdown-list_items">
                                          <li
                                             className="dropdown-list_item"
                                             key={'Not selected'}
                                             onClick={() => handleSelectSubsection(null)}
                                          >
                                             <div className="dropdown-list_item-block">
                                                Не выбрано
                                             </div>
                                          </li>
                                          {templateSubsections.map((subsection) => {
                                             return (
                                                <li
                                                   className="dropdown-list_item"
                                                   key={subsection.subsection_id}
                                                   onClick={() => handleSelectSubsection(subsection)}
                                                >
                                                   <div className="dropdown-list_item-block">
                                                      {subsection.name}
                                                   </div>
                                                </li>
                                             )
                                          })}
                                       </ul>)
                                    : (<p>На данный момент у вас нет созданных групп шаблонов!</p>)
                                 }
                              </div>
                           </div>
                        )}
                     </div>
                  </div>
               </div>
               <div
                  className="add-contact-form-control"
                  style={{ display: 'flex', flexDirection: 'row', gap: '8px' }}
               >
                  <label
                     className={
                        formik.values.templateFiles.length === 0
                           ? 'settings-template_file-button'
                           : 'settings-template_file-button-limit'
                     }
                     htmlFor="templateFiles"
                  >
                     <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                        fill={
                           formik.values.templateFiles.length === 0
                              ? '#40b7d9'
                              : '#B8C5C9'
                        }
                     >
                        <path
                           fill-rule="evenodd"
                           clip-rule="evenodd"
                           d="M15.3587 4.95927C16.8232 3.49478 19.1976 3.4948 20.6621 4.95926L20.1328 5.4885L20.6621 4.95927C22.1265 6.42372 22.1265 8.79808 20.6621 10.2626L20.6621 10.2626L11.8232 19.1014C9.77296 21.1516 6.44884 21.1516 4.39859 19.1014L4.92892 18.571L4.39859 19.1014C2.34834 17.0511 2.34834 13.727 4.39859 11.6768L11.8232 4.25215C12.1161 3.95925 12.591 3.95925 12.8839 4.25215C13.1767 4.54504 13.1767 5.01991 12.8839 5.31281L5.45925 12.7374L4.92892 12.2071L5.45925 12.7374C3.99478 14.2019 3.99478 16.5763 5.45925 18.0407L4.93823 18.5617L5.45925 18.0407C6.92371 19.5052 9.29809 19.5052 10.7625 18.0407L11.2575 18.5357L10.7625 18.0407L19.6014 9.20193C19.6014 9.20192 19.6014 9.20192 19.6014 9.20192C20.4801 8.3232 20.48 6.89857 19.6014 6.01993C18.7227 5.14123 17.2981 5.14124 16.4194 6.01992L16.4194 6.01993L7.58058 14.8587C7.28769 15.1516 7.28769 15.6265 7.58058 15.9194C7.87349 16.2123 8.34835 16.2123 8.64124 15.9194L16.0658 8.49477C16.3587 8.20187 16.8336 8.20187 17.1265 8.49477C17.4194 8.78766 17.4194 9.26253 17.1265 9.55543L9.70191 16.9801C8.82321 17.8588 7.39859 17.8588 6.5199 16.9801L6.51987 16.9801C5.64126 16.1014 5.64126 14.6768 6.51987 13.7981L6.5199 13.7981L15.3587 4.95928C15.3587 4.95927 15.3587 4.95927 15.3587 4.95927Z"
                        />
                     </svg>
                     {formik.values.templateFiles.length === 0
                        ? 'Прикрепить файл'
                        : formik.values.templateFiles[0].name || 'Файл'}
                  </label>
                  <input
                     id="templateFiles"
                     name="templateFiles"
                     type="file"
                     onChange={handleTemplateFilesChange}
                     className="settings-template_file-input"
                     accept=".jpg, .png, .gif, .jpeg, .webp, .bmp, .mp4, .m4a"
                  />
                  {formik.values.templateFiles.length !== 0 && (
                     <img
                        width="16"
                        height="16"
                        style={{ margin: '0', cursor: 'pointer' }}
                        src="/assets/controls/close.svg"
                        onClick={() => {
                           formik.setFieldValue('templateFiles', [])
                        }}
                     ></img>
                  )}
               </div>

               {displayWarningBlock && (
                  <WarningBlock
                     title={'Выделенные поля обязательны для заполнения.'}
                  />
               )}
               {/* {nameTemplateFieldEnough && (
                  <WarningBlock title={'Введите более 3 символов.'} />
               )} */}
               <div className="add-contact-form-submit">
                  <Button
                     type='submit'
                     text={disableSubmitBtn ? 'Создание...' : text}
                     disabled={disableSubmitBtn}
                  />
               </div>
            </form>
         </div>
      </CSSTransition>
   )
}

export default AddTemplate
