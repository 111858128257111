import React, { useState, useEffect } from 'react'

import './ContactsSelect.css'

import { useClickOutside } from '../../../../hooks'

const ContactsSelect = ({ 
   items, 
   selectedItems, 
   search, onSelectAll, 
   onSearchChange, 
   onSelect, 
   onDelete, 
   onScroll 
}) => {  
   const [isActive, setIsActive] = useState(false)
   const [searchValue, setSearchValue] = useState(search)
   const [isAllSelected, setIsAllSelected] = useState(items.length === selectedItems.length)
   const [disableScroll, setDisableScroll] = useState(false)
   
   const handleToggleIsActive = () => setIsActive(isActive => !isActive)

   const handleCloseSelect = () => setIsActive(false)

   const handleSearchValueChange = (e) => {
      const value = e.target.value.trim()
      setSearchValue(value)
      onSearchChange(value)
   }

   const handleSearchValueClear = () => {
      setSearchValue('')
      onSearchChange('')
   }

   const handleSelectAll = () => {
      onSelectAll(items)
   }

   const handleDelete = (e, id) => {
      e.stopPropagation()
      onDelete(id)
   }

   const handleScroll = async (e) => {
      if (e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight && !disableScroll) {
         setDisableScroll(true)
         await onScroll()
         setDisableScroll(false)
      }
   }
   
   const handleImageLoadingError = (e) => {
      e.target.src = '/assets/sidebar/default_avatar.png'
   }

   const ref = useClickOutside(handleCloseSelect)
   
   useEffect(() => {
      setIsAllSelected(items.length === selectedItems.length)
   }, [items, selectedItems])

   useEffect(() => {
      setSearchValue(search)
   }, [search])
   
   return (
      <div ref={ref} className={`add-mailing-contacts-select ${isActive ? 'active' : ''}`}>
         <button
            className='add-mailing-contacts-select__btn'
            onClick={handleToggleIsActive}
         >
            <div className='add-mailing-contacts-select__value'>
               {!selectedItems.length || isActive
                  ? 'Выберите контакты для рассылки'
                  : (
                     <ul className='add-mailing-contacts-select__value-list'>
                        {selectedItems.map(item => (
                           <li key={item.id} className='add-mailing-contacts-select__value-item'>
                              <img 
                                 className='add-mailing-contacts-select__value-item-avatar' 
                                 src={item.photo || ''} alt=''
                                 onError={handleImageLoadingError}
                              />
                              <span className='add-mailing-contacts-select__value-item-text'>
                                 {item.username}
                              </span>
                              <button 
                                 className='add-mailing-contacts-select__value-item-delete-btn'
                                 onClick={(e) => handleDelete(e, item.id)}
                              >
                                 <img src='/assets/controls/close.svg' alt='' />
                              </button>
                           </li>
                        ))}
                     </ul>
                  )}
               <img 
                  className='add-mailing-contacts-select__dropdown-icon'
                  src='/assets/sidebar/select-dropdown-icon.svg' alt='' 
               />
            </div>
         </button>
         <div className='add-mailing-contacts-select__list-wrapper'>
            <div className='add-mailing-contacts-select__search'>
               <img className='add-mailing-contacts-select__search-icon' src='/assets/controls/search-icon.svg' alt='' />
               <input 
                  className='add-mailing-contacts-select__search-input'
                  type="text"
                  value={searchValue}
                  onChange={handleSearchValueChange}
                  placeholder='Поиск'
               />
               {searchValue && (
                  <img 
                     className='add-mailing-contacts-select__close-icon' 
                     src='/assets/chat/xmark.svg' 
                     alt='' 
                     onClick={handleSearchValueClear}
                  />
               )}
            </div>
            {selectedItems.length > 0 && (
               <div className='add-mailing-contacts-select__selected-contacts-list-wrapper'>
                  <ul className='add-mailing-contacts-select__value-list add-mailing-contacts-select__selected-contacts-list'>
                     {selectedItems.map(item => (
                        <li key={item.id} className='add-mailing-contacts-select__value-item add-mailing-contacts-select__selected-contacts-list-item'>
                           <img 
                              className='add-mailing-contacts-select__value-item-avatar' 
                              src={item.photo || ''} alt=''
                              onError={handleImageLoadingError}
                           />
                           <span className='add-mailing-contacts-select__value-item-text'>
                              {item.username}
                           </span>
                           <button 
                              className='add-mailing-contacts-select__value-item-delete-btn'
                              onClick={(e) => handleDelete(e, item.id)}
                           >
                              <img src='/assets/controls/close.svg' alt='' />
                           </button>
                        </li>
                     ))}
                  </ul>
               </div>
            )}
            <ul 
               className='add-mailing-contacts-select__list'
               onScroll={handleScroll}   
            >
               {
                  items.length > 0
                     ? (
                        <>
                           <li
                              className={`add-mailing-contacts-select__list-item ${isAllSelected ? 'add-mailing-contacts-select__list-item_selected' : ''}`}
                              onClick={handleSelectAll}
                           >
                              <div className='add-mailing-contacts-select__list-item-checkbox'>
                                 <img src='/assets/sidebar/select-checkbox-icon.svg' alt='' />
                              </div>
                              <p className='add-mailing-contacts-select__list-item-text'>
                                 Выбрать все контакты
                              </p>
                           </li>
                           {items.map(item => {
                              const isSelected = selectedItems.some(selectedItem => selectedItem.id === item.id)
                              
                              return (
                                 <li 
                                    key={item.id}
                                    className={`contacts-subselect__list-item ${isSelected ? 'contacts-subselect__list-item_selected' : ''}`}
                                    onClick={() => onSelect(item)}
                                 >
                                    <div className='contacts-subselect__list-item-checkbox'>
                                       <img src='/assets/sidebar/select-checkbox-icon.svg' alt='' />
                                    </div>
                                    <div className='contacts-subselect__contact'>
                                       <div className='contacts-subselect__contact-avatar'>
                                          <img 
                                             className='contacts-subselect__contact-avatar-image' src={item.photo} alt=''
                                             onError={handleImageLoadingError}
                                          />
                                          <img className='contacts-subselect__contact-channel-icon' src={`/assets/sidebar/${item.domain}-icon.svg`} alt="" />
                                       </div>
                                       <span className='contacts-subselect__contact-name'>
                                          {item.username}
                                       </span>
                                    </div>
                                 </li>
                              )
                           })}
                        </>
                     ) : (
                        'По вашему запросу ничего не найдено'
                     )
               }
            </ul>
         </div>
      </div>
   )
}

export default ContactsSelect