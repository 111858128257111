import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import * as yup from 'yup'

import '../auth.css'
import './ResetPasswordPage.css'

import routeNames from '../../utils/consts/routeNames'

import { Button } from '../../components/Button'

const ResetPasswordPage = ({ token, onSubmit }) => {
   const [disableSubmitBtn, setDisableSubmitBtn] = useState(false)
   const [showPass, setShowPass] = useState(false)
   const [showRepeatPass, setShowRepeatPass] = useState(false)

   const navigate = useNavigate()

   const handleReturn = () => navigate(routeNames.RESET_PASSWORD_EMAIL_SENT)

   const handleToggleShowPassword = () => setShowPass((show) => !show)

   const handleToggleShowRepeatPassword = () =>
      setShowRepeatPass((show) => !show)

   const formik = useFormik({
      initialValues: {
         email: '',
         password: '',
         repeatPassword: '',
      },
      validationSchema: yup.object().shape({
         email: yup
            .string()
            .matches(
               /^[^\s@]+@[^\s@.]+\.[^\s@.]+$/,
               'Пожалуйста, введите корректный email-адрес'
            )
            .required('Email-адрес обязателен для заполнения'),
         password: yup
            .string()
            .min(8, 'Пароль должен содержать не менее 8 символов')
            .required('Пароль обязателен для заполнения'),
         repeatPassword: yup
            .string()
            .oneOf([yup.ref('password')], 'Пароли должны совпадать')
            .required('Повторите пароль'),
      }),
      onSubmit: async ({ email, password }, { setStatus }) => {
         try {
            setDisableSubmitBtn(true)
            await onSubmit({ email, password, token })
         } catch (err) {
            setStatus(err)
         } finally {
            setDisableSubmitBtn(false)
         }
      },
   })

   return (
      <div className="auth">
         <div className="login-card reset-password-page__card">
            <div className="login-card-wrapper reset-password-page__wrapper">
               <div className="reset-password-page__header">
                  <div className="reset-password-page__header-title">
                     <button
                        type="button"
                        className="reset-password-page__return-btn"
                        onClick={handleReturn}
                     >
                        <img
                           src="/assets/controls/return-back-icon.svg"
                           alt=""
                        />
                     </button>
                     <h4 className="reset-password-page__header-text">
                        Укажите новый пароль
                     </h4>
                  </div>
               </div>
               <form
                  className="reset-password-form"
                  onSubmit={formik.handleSubmit}
               >
                  <div className="reset-password-form__body">
                     {formik.status && (
                        <p className="forgot-password-form__status">
                           {formik.status}
                        </p>
                     )}
                     <div className="reset-password-form-control">
                        <label
                           htmlFor="email"
                           className={`reset-password-form-control__label ${
                              formik.touched.email && formik.errors.email
                                 ? 'reset-password-form-control__label_error'
                                 : ''
                           }`}
                        >
                           {(formik.touched.email && formik.errors.email) ||
                              'Email'}
                        </label>
                        <input
                           type="text"
                           className="login-card-credentials-field reset-password-form-input"
                           id="email"
                           name="email"
                           value={formik.values.email}
                           onChange={formik.handleChange}
                           onBlur={formik.handleBlur}
                           placeholder="example@mail.com"
                           autoComplete="off"
                        />
                     </div>
                     <div className="reset-password-form-control">
                        <label
                           htmlFor="password"
                           className={`reset-password-form-control__label ${
                              formik.touched.password && formik.errors.password
                                 ? 'reset-password-form-control__label_error'
                                 : ''
                           }`}
                        >
                           {(formik.touched.password &&
                              formik.errors.password) ||
                              'Пароль'}
                        </label>
                        <div className="reset-password-form-control__body">
                           <input
                              type={showPass ? 'text' : 'password'}
                              className="login-card-credentials-field reset-password-form-input reset-password-form-input_password"
                              id="password"
                              name="password"
                              value={formik.values.password}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              placeholder="**********"
                              autoComplete="off"
                           />
                           <img
                              className={`reset-password-form-input__show-btn ${
                                 showPass
                                    ? 'reset-password-form-input__show-btn_active'
                                    : ''
                              }`}
                              src={`/assets/controls/password-${
                                 showPass ? 'visible' : 'hidden'
                              }-icon.svg`}
                              alt=""
                              onClick={handleToggleShowPassword}
                           />
                        </div>
                     </div>
                     <div className="reset-password-form-control">
                        <label
                           htmlFor="password"
                           className={`reset-password-form-control__label ${
                              formik.touched.repeatPassword &&
                              formik.errors.repeatPassword
                                 ? 'reset-password-form-control__label_error'
                                 : ''
                           }`}
                        >
                           {(formik.touched.repeatPassword &&
                              formik.errors.repeatPassword) ||
                              'Подтвердите пароль'}
                        </label>
                        <div className="reset-password-form-control__body">
                           <input
                              type={showRepeatPass ? 'text' : 'password'}
                              className="login-card-credentials-field reset-password-form-input reset-password-form-input_password"
                              id="repeatPassword"
                              name="repeatPassword"
                              value={formik.values.repeatPassword}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              placeholder="**********"
                              autoComplete="off"
                           />
                           <img
                              className={`reset-password-form-input__show-btn ${
                                 showRepeatPass
                                    ? 'reset-password-form-input__show-btn_active'
                                    : ''
                              }`}
                              src={`/assets/controls/password-${
                                 showRepeatPass ? 'visible' : 'hidden'
                              }-icon.svg`}
                              alt=""
                              onClick={handleToggleShowRepeatPassword}
                           />
                        </div>
                     </div>
                  </div>
                  <Button
                     type="submit"
                     text="Сохранить"
                     disabled={disableSubmitBtn}
                  />
               </form>
            </div>
         </div>
      </div>
   )
}

export default ResetPasswordPage